import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './pages/Home/home.css';
import App from './App';
import Confirmation from './confirmation';
import Login from './pages/Login/Login';
import reportWebVitals from './reportWebVitals';
import Dashboard from './dashboard';
import Repsonses from './Responses';
import Locations from './locations';
import Home from './pages/Home/home';
import { AuthProvider } from './AuthProvider';
import { CardProvider } from './CardProvider';
import RequireAuth from './PrivateRoute';
import PublicRoute from './PublicRoute';

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import Store from './Store';

document.documentElement.style.setProperty(
  '--vh',
  `${window.innerHeight / 100}px`
);

ReactDOM.render(
  <React.StrictMode>
    <Store>
      <CardProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
                <Route path="/survey/:id" element={<App />} />
                <Route exact path="/login" element={
                 <PublicRoute>
                    <Login />
                 </PublicRoute> 
                } />
                <Route path="/dashboard" element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                } />
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                <Route path="/responses" element={<Repsonses />} />
                <Route path="/locations" element={<Locations />} />
                <Route exact path="/confirmation" element={<Confirmation />} />
                <Route exact path="/" element={<Home />} />
            </Routes>
            </BrowserRouter>
        </AuthProvider>
      </CardProvider>
    </Store>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
