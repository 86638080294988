import { db, firebaseAuth } from '../config/constants';

export function auth(email, pw) {
  return firebaseAuth()
    .createUserWithEmailAndPassword(email, pw)
    .then(saveUser);
}

export function logout() {
  return firebaseAuth().signOut();
}

export function login(email, pw) {
  return firebaseAuth().signInWithEmailAndPassword(email, pw);
}

export function resetPassword(email) {
  return firebaseAuth().sendPasswordResetEmail(email);
}

export function saveUser({ user }) {
  return db
    .collection(`users`)
    .doc(user.uid)
    .add({
      email: user.email,
      uid: user.uid,
    })
    .then((docRef) => console.log(docRef))
    .catch(function (error) {
      console.error('Error adding document: ', error);
    });
}
