import React, { useEffect, useState, useContext } from 'react';
import { firebaseAuth, db } from './config/constants';
import { CardContext } from './CardProvider';


export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [locations, setLocations] = useState([]);

  const { setCardData, setFilteredCardData } = useContext(CardContext);
  useEffect(() => {
    firebaseAuth().onAuthStateChanged((user) => {
      console.log('auth state changed!', user)
      if (user) {
        setCurrentUser(user);
        db.collection('users')
          .where('uid', '==', user.uid)
          .get()
          .then((querySnapshot) => {
            const userLocations = querySnapshot.docs[0].data().codes;
            setLocations(userLocations);
            const locationNames = userLocations.map(({ id }) => id);
            locationNames.map((location) => {
              return db
                .collection(location)
                .where('timestamp', '!=', null)
                .get()
                .then((snapshot) => {
                  setCardData((prevState) => [
                    ...prevState,
                    ...snapshot.docs.map((doc) => doc.data()),
                  ]);
                  setFilteredCardData((prevState) => [
                    ...prevState,
                    ...snapshot.docs.map((doc) => doc.data()),
                  ]);
                  setPending(false);
                });
              });
          })
          .then(() => {
            setPending(false);
          })
          .catch((e) => setPending(false));
      } else {
        setCurrentUser(null);
        setCardData([]);
        setFilteredCardData([]);
        setPending(false);
      }
    });
  }, [setCardData, setFilteredCardData]);

  return pending ? (
    <>Loading.....</>
  ) : (
    <AuthContext.Provider
      value={{
        currentUser: { ...currentUser, locations },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  console.log('authState: ', !!auth.currentUser.uid);
  return { ...auth, isAuthenticated: !!auth.currentUser.uid };
};
