import React, { useContext, useEffect } from 'react';
import { Context } from './Store';
import GoogleLogo from './img/google-logo.png';

const Confirmation = () => {
  const [state] = useContext(Context);
  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0&appId=911310272277576&autoLogAppEvents=1';
    script.async = true;
    script.defer = true;
    script.crossorigin = 'anonymous';
    script.nonce = 'hjUcgx0j';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="confirmation-container">
      <center>
        <div className="confirmation-message">
          <h1>
            {state.emotion && state.emotions[state.emotion].confirmationTitle}
          </h1>
          <h2>
            {state.emotion && state.emotions[state.emotion].confirmationMessage}
          </h2>
          <div
            className="google-logo"
            onClick={() => (window.location = 'https://www.google.com')}
          >
            <img
              src={GoogleLogo}
              alt="google-logo"
              width="50px"
              height="50px"
            />
            <div>
              <p>
                Click here to leave us a review on <strong>Google!</strong>
              </p>
            </div>
          </div>
          <div id="fb-root"></div>
          <div
            className="fb-page"
            data-href="https://www.facebook.com/facebook/"
            data-tabs=""
            data-width="250"
            data-height=""
            data-small-header="true"
            data-adapt-container-width="true"
            data-hide-cover="false"
            data-show-facepile="false"
          ></div>
        </div>
      </center>
    </div>
  );
};

export default Confirmation;
