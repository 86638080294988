import React, { useContext } from 'react';
import { Route, Redirect, useLocation, Navigate } from 'react-router-dom';
import { useAuthState } from './AuthProvider';

const PublicRoute = ({ children }) => {
  let location = useLocation();
  const { isAuthenticated } = useAuthState()

  if (isAuthenticated) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
}

export default PublicRoute;
