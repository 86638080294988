import React, { useState } from 'react';
import Phone from '../../img/tastyBites.png'
import { Button } from '@mui/material';
import firebase from 'firebase';


const Home = () => {

const onSubmit = () => {
  
  firebase.firestore()
    .collection('emailList')
    .add({email})
    .then(() => alert("Thank you! We'll be in touch soon."))
    .catch(e => console.log(e))
}
const [email, setEmail] = useState('');

  return (
    <div className="container">
      <div className='home-container'>
        <div className='navbar'>
          <div className="nav-item">Features</div>
          <div className="nav-item">Pricing</div>
          <div className="nav-item">About</div>
          <div className="nav-item">Contact</div>
        </div>
        <div className='home-body'>
          <div className='home-content'>
            <div className='home-title'>
              <h1>The simple way to get feedback</h1>
              <h2>
                Get instant feedback from your customers and learn how to better serve them.
              </h2>
            </div>
            <div className='home-form'>
              <input className='home-input' type="text" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Enter your email" />
              <div className='home-button'>
                <Button variant="contained" color="primary" onClick={onSubmit} >Join Waitlist</Button>
              </div>
            </div>
          </div>
          <div className="phone">
             <img src={Phone} width="300" alt="phone" />
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default Home;