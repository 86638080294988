import React, { useContext, useState } from 'react';
import { AuthContext } from './AuthProvider';
import Layout from './Layout';
import QRCode from 'react-qr-code';
import Card from '@mui/material/Card';
import short from 'short-uuid';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import firebase from 'firebase';
import { db } from './config/constants';

const Dashboard = () => {
  const { currentUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [locationName, setLocationName] = useState('');
  const locationNames = currentUser.locations;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = () => {
    const locationId = short.generate();
    console.log('currentUser', currentUser);
    if (locationName) {
      db.collection('users').doc(currentUser.uid).update({
        codes: firebase.firestore.FieldValue.arrayUnion( { id: locationId, name: locationName } ),
      });
      db.collection(locationId).doc('data').set({
        displayName: locationName,
        email: currentUser.email,
        sendEmail: true,
      });
      locationNames.push({ id: locationId, name: locationName });
      setLocationName('');
      handleClose();
    }
  };

  const link = (locationName) =>
    `https://www.feedbacksimple.com/survey/${locationName}`;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <Layout />

      <div className="locations-container">
        <center>
          <div className="cards-container">
            {locationNames.map((location) => (
              <Card className="dashboard-card">
                <CardContent>
                  <h1>{location.name}</h1>
                  <h2>
                    URL:{' '}
                    <a href={link(location.id)} rel="noreferrer" target="_blank">
                      {location.id}
                    </a>
                  </h2>
                  <QRCode value={link(location.id)} />
                </CardContent>
              </Card>
            ))}
            <Fab 
              color="primary" 
              aria-label="add" 
              className='fab' 
              sx={{
                position: 'fixed',
                bottom: '2rem',
                right: '2rem',
              }}
              onClick={() => handleOpen()}>
                <AddIcon />
            </Fab>
          </div>
        </center>
        
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" sx={style}>
          <div className="add-location">
            <Typography variant="h6" gutterBottom>Add new location:</Typography>
            <TextField
              label="Location Name"
              variant="outlined"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Submit</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Dashboard;
