import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { logout } from './helpers/auth';


const Layout = () => {
  const [drawerState, setDrawerState] = useState(false);
  const navigate = useNavigate();

  const handleSignOut = () => {
    logout();
  };
  const toggleDrawer = (openState) => {
    setDrawerState(openState);
  };
  const drawerWidth = 240;
  const drawer = (
    <div role="presentation" onClick={() => toggleDrawer(false)}>
      <div className="menu-top" />
      <Divider />
      <List>
        {[
          { text: 'Dashboard', link: 'dashboard', icon: <DashboardIcon /> },
          { text: 'Locations', link: 'locations', icon: <PinDropIcon /> },
        ].map((item, index) => (
          <ListItem
            button
            key={item.text}
            onClick={() => navigate(`/${item.link}`)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        <ListItem button key="Logout" onClick={handleSignOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
  return (
    <>
        <Drawer
          className="drawer-menu"
          variant="temporary"
          anchor="left"
          open={drawerState}
          onClose={() => toggleDrawer(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
        <Drawer 
          variant="permanent" 
          open
          className="drawer-menu"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          >
          {drawer}
        </Drawer>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            className={'menuButton'}
            color="inherit"
            aria-label="menu"
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Layout;
