import React, { createContext, useReducer } from 'react';
import Reducer from './Reducer';

const initialState = {
  emotion: null,
  activeCard: 0,
  emotions: {
    positive: {
      title: `That's great!`,
      subtitle: `What did you like the most?`,
      emoji: <>&#128512;</>,
      feedbackTitle: 'Glad to hear that!',
      feedbackSubtitle: 'Feel free to send me a quick message',
      confirmationTitle: 'Thank you!',
      confirmationMessage:
        'Please let others know about your great experience!',
    },
    neutral: {
      title: `We can do better!`,
      subtitle: `Where could we have improved?`,
      emoji: <>&#128528;</>,
      feedbackTitle: 'I know we can do better!',
      feedbackSubtitle: 'Let us know a bit more how we can serve you better.',
      confirmationTitle: 'Thank you!',
      confirmationMessage: 'Hope to see you again soon!',
    },
    negative: {
      title: `Sorry to hear that!`,
      subtitle: `What went wrong?`,
      emoji: <>&#128577;</>,
      feedbackTitle: 'Let me make it up to you',
      feedbackSubtitle:
        'Consider giving us more information so we can address this.',
      confirmationTitle: 'Thank you!',
      confirmationMessage:
        "We're sorry you didn't have the best experience. We'll make it right!",
    },
  },
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
