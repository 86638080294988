import React, { useState, useEffect } from 'react';
import { auth, login, resetPassword } from '../../helpers/auth';

import { Box, Button, Card, Divider, Grid, TextField, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import LockIcon from '@mui/icons-material/Lock';

const Login = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loginMessage, setLoginMessage] = useState(null);
  const [loginMode, setLoginMode] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    loginMode
      ? login(email, password)
      .catch((error) => {
          setLoginMessage('Invalid username/password.');
        })
      : auth(email, password)
      .catch((e) => setLoginMessage(e.message));
  };
  const onResetPassword = () => {
    resetPassword(email)
      .then(() => setLoginMessage(`Password reset email sent to ${email}.`))
      .catch((error) => setLoginMessage(`Email address not found.`));
  };

  console.log('rending login');

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={10} md={6} lg={4} xl={4}>
        <Card raised>
          <form autoComplete="on" style={style.container} onSubmit={(event) => handleSubmit(event)}>
          <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
            <LockIcon color="primary" sx={{ marginTop: '1rem'}}/>
            <Typography m={'1rem'} sx={{fontWeight: 700}}>{loginMode ? 'Login' : 'Sign Up'}</Typography>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<GoogleIcon />}
              sx={{ boxShadow: 1, marginBottom: '1rem' }}
            >
              Continue with Google
            </Button>
            <Divider flexItem>Or</Divider>
            <TextField
              label="Email"
              name="email"
              onChange={(event) => setEmail(event.target.value)}
              required
              sx={{ margin: '1rem 0 .5rem 0', width: '80%' }}
            />
            <TextField
              type="password"
              label="Password"
              name="current-password"
              onChange={(event) => setPassword(event.target.value)}
              required
              sx={{ margin: '.5rem 0', width: '80%' }}
            />
            {!loginMode && (
              <TextField
                type="password"
                label="Confirm Password"
                error={password !== confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                required
                sx={{ margin: '10px 0', width: '80%' }}
              />
            )}
            {loginMessage && (
              <div className="alert alert-danger" role="alert">
                <span
                  className="glyphicon glyphicon-exclamation-sign"
                  aria-hidden="true"
                />
                <span className="sr-only">Error:</span>
                &nbsp;{loginMessage}{' '}
                <button onClick={onResetPassword} className="alert-link">
                  Forgot Password?
                </button>
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ margin: '1rem 0 2rem 0' }}
            >
              {loginMode ? 'Login' : 'Sign Up'}
            </Button>
            </Grid>
          </form>
        </Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '3rem 1rem 3rem 1rem'}}>
          <div>{loginMode ? 'Not registered?' : 'Already registered?'}</div>
          <Button
            color="secondary"
            onClick={() => setLoginMode(!loginMode)}
          >
            {loginMode ? 'Sign Up' : 'Login'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const container = {
  textAlign: 'center',
};

const style = {
  container,
};

export default Login;
