import React, { useState, useContext, useEffect } from 'react';
import firebase from 'firebase';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { useParams, Navigate } from 'react-router-dom';
import { db } from './config/constants';
import { Context } from './Store';
import Survey from './survey';
import './App.css';

const App = () => {
  const { id } = useParams();

  const [state, dispatch] = useContext(Context);
  // const [indicators, setIdicators] = useState({
  //   '001': {
  //     id: '001',
  //     name: 'Quality',
  //     checked: false,
  //   },
  //   '002': {
  //     id: '002',
  //     name: 'Cleanliness',
  //     checked: false,
  //   },
  //   '003': {
  //     id: '003',
  //     name: 'Customer Service',
  //     checked: false,
  //   },
  // });
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [storeData, setStoreData] = useState({});
  useEffect(() => {
    db.collection(id)
      .doc('data')
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        setStoreData(data);
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }, [id]);

  const useStyles = makeStyles((theme) => ({
    form: {
      '& .MuiTextField-root': {
        // margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }));

  const classes = useStyles();
  const nextCard = () => {
    dispatch({ type: 'SET_ACTIVE_CARD', payload: state.activeCard + 1 });
  };

  const selectEmotion = (index) => {
    dispatch({ type: 'SET_EMOTION', payload: index });
    nextCard();
  };

  // const handleChange = (event) => {
  //   const newState = {
  //     ...indicators[event.target.name],
  //     checked: event.target.checked,
  //   };
  //   setIdicators({ ...indicators, [event.target.name]: newState });
  // };

  // const checkboxItemClass = (id) =>
  //   classNames({
  //     'checkbox-item': true,
  //     checked: indicators[id].checked,
  //   });

  const nameText = storeData.displayName
    ? ' with ' + storeData.displayName
    : '';
  const cards = [
    {
      title: `How was your overall experience${nameText}?`,
      content: (
        <div className="card-initial-content">
          {Object.keys(state.emotions).map((emotion, index) => (
            <div
              className="emoji"
              onClick={() => selectEmotion(emotion)}
              key={index}
            >
              {state.emotions[emotion].emoji}
            </div>
          ))}
        </div>
      ),
      button: undefined,
    },
    // {
    //   title: state.emotion && state.emotions[state.emotion].title,
    //   subtitle: state.emotion && state.emotions[state.emotion].subtitle,
    //   content: (
    //     <div>
    //       <div className="emoji">
    //         {state.emotion && state.emotions[state.emotion].emoji}
    //       </div>
    //       {Object.values(indicators).map((indicator) => (
    //         <label className={checkboxItemClass(indicator.id)}>
    //           <Checkbox
    //             name={indicator.id}
    //             onChange={handleChange}
    //             color="primary"
    //             className="checkbox"
    //             type="checkbox"
    //           />
    //           {indicator.name}
    //         </label>
    //       ))}
    //     </div>
    //   ),1
    //   button: {
    //     text: 'Next',
    //     action: nextCard,
    //   },
    // },
    {
      title: state.emotion && state.emotions[state.emotion].feedbackTitle,
      subtitle: state.emotion && state.emotions[state.emotion].feedbackSubtitle,
      content: (
        <form className={classes.form}>
          <TextField
            label="Your name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Message"
            multiline
            variant="outlined"
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </form>
      ),
      button: {
        text: 'Submit',
        action: () => {
          firebase
            .firestore()
            .collection(id)
            .add({
              emotion: state.emotion,
              // indicators,
              name,
              email,
              message,
              locationId: id,
              timestamp: new Date(),
            })
            .then(() => <Navigate to='/confirmation' />)
            .then(() => {
              storeData.sendEmail &&
                firebase
                  .firestore()
                  .collection('mail')
                  .add({
                    to: [storeData.email],
                    message: {
                      subject: `New customer feedback from ${storeData.displayName}`,
                      text: `Feedback was: ${state.emotion} \n Name: ${name} \n Email: ${email} \n Message: ${message}`,
                      html: `<!DOCTYPE html>
                      <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
                        <head>
                            <meta charset="UTF-8">
                            <meta name="viewport" content="width=device-width,initial-scale=1">
                            <meta name="x-apple-disable-message-reformatting">
                        </head>
                        <body>
                          <div style="background-color:#EEEE;height: 100vh;padding-top:1rem;">
                          <div style="background-color:white;max-width:400px;margin:auto;border-radius: 15px;box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);height: 400px;">
                            <center>
                            <img style="font-size:64px; padding-top: 2rem; padding-bottom: 2rem;" src="https://cdn.pixabay.com/photo/2020/12/18/01/27/smile-5840910_960_720.png" width="100px"/>
                             
                              <div style="font-size: 24px; padding-bottom: 2rem;">${message}</div>
                              <div>
                                <strong>Name: ${name}</strong>
                              </div>
                              <div>
                                <strong>Email: ${email}</strong>
                              </div>
                            </center>
                          </div>
                        </body>
                      </html>`,
                    },
                  });
            })
            .catch((e) => console.error('error writing doc: ', e));
        },
      },
    },
  ];

  return <Survey cards={cards} />;
};

export default App;
