import React, { useContext } from 'react';
import { AuthContext } from './AuthProvider';
import { Context } from './Store';
import Card from '@mui/material/Card';
import Layout from './Layout';
// import { Bar } from 'react-chartjs-2';
import Select from '@mui/material/Select';
import { CardContext } from './CardProvider';
import MenuItem from '@mui/material/MenuItem';
import { filter, groupBy, keys, prop, sort, reverse, sortBy } from 'ramda';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DateRangePicker from '@mui/lab/DateRangePicker';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';

const Dashboard = () => {
  const { currentUser } = useContext(AuthContext);
  const locationNames = currentUser.locations.map(({ id }) => id);
  const [state] = useContext(Context);

  const {
    cardData,
    filteredCardData,
    setFilteredCardData,
    selectedLocation,
    setSelectedLocation,
    selectedDate,
    setSelectedDate,
  } = useContext(CardContext);

  const chartData = groupBy((item) => {
    const date = prop('timestamp', item).toDate().toLocaleDateString();
    return date;
  }, filteredCardData);

  const chartLabels = sort(
    (date1, date2) => new Date(date1) - new Date(date2),
    keys(chartData)
  );
  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Positive',
        data: chartLabels.map(
          (label) =>
            filter(
              (entry) => prop('emotion', entry) === 'positive',
              chartData[label]
            ).length
        ),
        fill: false,
        backgroundColor: '#03a100',
      },
      {
        label: 'Neutral',
        data: chartLabels.map(
          (label) =>
            filter(
              (entry) => prop('emotion', entry) === 'neutral',
              chartData[label]
            ).length
        ),
        fill: false,
        backgroundColor: '#ffd000',
      },
      {
        label: 'Negative',
        data: chartLabels.map(
          (label) =>
            filter(
              (entry) => prop('emotion', entry) === 'negative',
              chartData[label]
            ).length
        ),
        fill: false,
        backgroundColor: '#de0000',
      },
    ],
  };

  const options = {
    onClick: (event, chartElements) => {
      if (chartElements) {
        console.log(event);
        console.log(chartElements);
      }
    },
    maintainAspectRatio: false,
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
    scales: {
      xAxis: {
        display: true,
      },
      yAxis: {
        display: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const handleChange = (event) => {
    const id = event.target.value;
    setSelectedLocation(id);
    const newCardData = cardData.filter(
      (card) =>
        card.locationId === id &&
        (selectedDate[0] !== null && selectedDate[1] !== null
          ? card.timestamp.toDate().setHours(0, 0, 0, 0, 0) ===
            new Date(selectedDate).setHours(0, 0, 0, 0, 0)
          : true)
    );
    if (id === 'all') {
      setFilteredCardData(cardData);
      setSelectedDate([null, null]);
    } else {
      setFilteredCardData(newCardData);
    }
  };

  const handleDateChange = (date) => {
    const filteredCards = cardData.filter(
      (card) =>
        (date[0] !== null && date[1] !== null
          ? card.timestamp.toDate().setHours(0, 0, 0, 0, 0) >=
            new Date(date[0]).setHours(0, 0, 0, 0, 0) && 
            card.timestamp.toDate().setHours(0, 0, 0, 0, 0) <=
            new Date(date[1]).setHours(0, 0, 0, 0, 0)
          : true) &&
        (selectedLocation === 'all'
          ? true
          : card.locationId === selectedLocation)
    );
    setSelectedDate(date);
    setFilteredCardData(filteredCards);
  };
  return (
    <>
      <Layout />

      <div className="main-container">
        <div className="toggle-toolbar">
        <Select
          className="location-toggle"
          value={selectedLocation}
          onChange={handleChange}
        >
          <MenuItem value={'all'}>View All</MenuItem>
          {currentUser.locations.map((location, index) => (
            <MenuItem value={location.id} key={locationNames[index]}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
          startText="Start Date"
          endText="End Date"
          label="Date"
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} />
            </React.Fragment>
          )}
            // className="date-picker"
            // disableToolbar
            // variant="dialog"
            // clearable
            // inputFormat="MM/dd/yyyy"
            // margin="normal"
            
            // autoOk
            // disableFuture
            // id="date-picker-inline"
            // KeyboardButtonProps={{
            //   'aria-label': 'change date',
            // }}
          />
        </LocalizationProvider>
          
          </div>
        <div className="info-cards">
          <Card className="total-container" sx={{ border: `3px solid #03a100` }}>
            <div className="card-count">
              {
                filteredCardData.filter((card) => card.emotion === 'positive')
                  .length
              }
            </div>
            <div>positive</div>
          </Card>
          <Card className="total-container" sx={{ border: `3px solid #ffd000` }}>
            <div className="card-count">
              {
                filteredCardData.filter((card) => card.emotion === 'neutral')
                  .length
              }
            </div>
            <div>neutral</div>
          </Card>
          <Card className="total-container" sx={{ border: `3px solid #de0000` }}>
            <div className="card-count">
              {
                filteredCardData.filter((card) => card.emotion === 'negative')
                  .length
              }
            </div>
            <div>negative</div>
          </Card>
          <Card className="total-container">
            <div className="card-count">{filteredCardData.length}</div>
            <div>total</div>
          </Card>
        </div>
        {/* <Card className="chart-container">
          <Bar data={data} options={options} height={'95%'} />
        </Card> */}
        <div className="cards-container">
          {filteredCardData.length > 0 &&
            reverse(sortBy(prop('timestamp'), filteredCardData)).map(
              (card, index) => {
                let borderColor = '#000'
                  switch (card.emotion) {
                    case 'positive':
                      borderColor = '#03a100';
                      break;
                    case 'neutral':
                      borderColor = '#ffd000';
                      break;
                    case 'negative':
                      borderColor = '#de0000';
                      break;
                    default:
                      borderColor = '#000000';
                  }
                return (
                  <Card 
                    className="dashboard-card"
                    sx={{ border: `3px solid ${borderColor}` }}
                    raised
                  >
                    <CardContent className={'card-content'}>
                      <center>
                        <div className="emoji">
                          {state.emotions[card.emotion].emoji}
                        </div>
                        {/* {card.indicators &&
                          Object.values(card.indicators, index).map(
                            (indicator) =>
                              indicator.checked && (
                                <Chip
                                  key={`${index}-${indicator.name}`}
                                  label={indicator.name}
                                  color="primary"
                                />
                              )
                          )} */}
                      </center>
                      <div className="card-text">
                        

                        {card.name && (
                          <div>
                            <strong>Name: </strong>
                            {card.name}
                          </div>
                        )}
                        {card.email && (
                          <div>
                            <strong>Email: </strong>
                            {card.email}
                          </div>
                        )}
                        <Typography variant="h6">{card.message}</Typography>
                      </div>
                      <Typography variant="subtitle1">
                        {new Date(card.timestamp.toDate()).toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              }
            )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
