import React, { useContext } from 'react';
import { Route, Redirect, useLocation, Navigate } from 'react-router-dom';
import { useAuthState } from './AuthProvider';

const RequireAuth = ({ children }) => {
  let location = useLocation();
  const { isAuthenticated } = useAuthState()

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
