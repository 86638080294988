import React, { useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';
import Company from './img/company-logo.png';
import { Context } from './Store';

const Survey = (props) => {
  const [state, dispatch] = useContext(Context);
  const { cards } = props;
  // const pillClass = (index) =>
  //   classNames({
  //     pill: true,
  //     active: state.activeCard >= index,
  //   });

  const cardClass = (index) =>
    classNames({
      card: true,
      active: state.activeCard >= index,
      hidden: state.activeCard !== index && !state.emotion,
    });

  const styles = {
    root: {
      padding: '20px 20px 20px 20px',
      backgroundColor: '#EBEBEB',
      maxWidth: '600px',
      margin: '0 auto',
    },
    slide: {
      padding: 5,
      minHeight: 100,
    },
    slideStyle: {
      background: '#EBEBEB',
    },
  };

  return (
    <div className="container">
      {/* <div className="pill-container">
        {cards.map((card, index) => (
          <div className={pillClass(index)} key={index}></div>
        ))}
      </div> */}
      <SwipeableViews
        style={styles.root}
        index={state.activeCard}
        disabled={!!!state.emotion}
        slideStyle={styles.slideContainer}
        onChangeIndex={(index) =>
          dispatch({ type: 'SET_ACTIVE_CARD', payload: index })
        }
      >
        {cards.map((card, cardIndex) => (
          <div
            style={{ ...styles.slide, ...styles.slideStyle }}
            key={cardIndex}
          >
            <div className={cardClass(cardIndex)}>
              <div className="card-title">Tasty Bites</div>
              <img src={Company} className="logo" alt="company logo" />
              <div className="card-subtitle">
                <div className="title">{card.title}</div>
                {card.subtitle && (
                  <div className="subtitle">{card.subtitle}</div>
                )}
              </div>

              <div className="content">{card.content}</div>
              {card.button ? (
                <div className="next-button" onClick={card.button.action}>
                  {card.button.text}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        ))}
      </SwipeableViews>
    </div>
  );
};

export default Survey;
