import React, { useContext } from 'react';
import { AuthContext } from './AuthProvider';
import { CardContext } from './CardProvider';
import { Context } from './Store';

import Layout from './Layout';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { prop, reverse, sortBy } from 'ramda';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const Responses = () => {
  const { currentUser } = useContext(AuthContext);
  const {
    cardData,
    filteredCardData,
    setFilteredCardData,
    selectedLocation,
    setSelectedLocation,
    selectedDate,
    setSelectedDate,
  } = useContext(CardContext);

  const [state] = useContext(Context);
  const locationNames = currentUser.locations.map(({ id }) => id);


  const handleChange = (event) => {
    const id = event.target.value;
    setSelectedLocation(id);
    setSelectedDate([]);
    const newCardData = cardData.filter((card) => card.locationId === id);
    id === 'all'
      ? setFilteredCardData(cardData)
      : setFilteredCardData(newCardData);
  };
  const handleDateChange = (date) => {
    const filteredCards = filteredCardData.filter(
      (card) =>
        card.timestamp.toDate().setHours(0, 0, 0, 0, 0) ===
        new Date(date).setHours(0, 0, 0, 0, 0)
    );
    setSelectedDate(date);
    setFilteredCardData(filteredCards);
  };
  return (
    <>
      <Layout />
      <div className="main-container">
        <Select
          className="location-toggle"
          value={selectedLocation}
          onChange={handleChange}
        >
          <MenuItem value={'all'}>View All</MenuItem>
          {currentUser.locations.map((location, index) => (
            <MenuItem value={location.id} key={locationNames[index]}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            label="Date"
            id="date-picker-inline"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            renderInput={(props) => <TextField {...props} />}
          />
        </LocalizationProvider>

        <div className="cards-container">
          {filteredCardData.length > 0 &&
            reverse(sortBy(prop('timestamp'), filteredCardData)).map(
              (card, index) => {
                return (
                  <Card className="dashboard-card">
                    <CardContent>
                      <center>
                        <div className="emoji" key={`${index}-${card.id}`}>
                          {state.emotions[card.emotion].emoji}
                        </div>
                        {Object.values(card.indicators, index).map(
                          (indicator) =>
                            indicator.checked && (
                              <Chip
                                key={`${index}-${indicator.name}`}
                                label={indicator.name}
                                color="primary"
                              />
                            )
                        )}
                      </center>
                      <div className="card-text">
                        <Typography variant="h6">{card.message}</Typography>

                        {card.name && (
                          <div>
                            <strong>Name: </strong>
                            {card.name}
                          </div>
                        )}
                        {card.email && (
                          <div>
                            <strong>Email: </strong>
                            {card.email}
                          </div>
                        )}
                      </div>
                      <Typography variant="subtitle1">
                        {new Date(card.timestamp.toDate()).toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              }
            )}
        </div>
      </div>
    </>
  );
};

export default Responses;
