const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_EMOTION':
      return {
        ...state,
        emotion: action.payload,
      };
    case 'SET_ACTIVE_CARD':
      return {
        ...state,
        activeCard: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
