import React, { useState } from 'react';
export const CardContext = React.createContext();

export const CardProvider = ({ children }) => {
  const [selectedLocation, setSelectedLocation] = useState('all');
  const [selectedDate, setSelectedDate] = useState([null, null]);

  const [cardData, setCardData] = useState([]);
  const [filteredCardData, setFilteredCardData] = useState([]);


  return (
    <CardContext.Provider
      value={{
        cardData,
        setCardData,
        filteredCardData,
        setFilteredCardData,
        selectedLocation,
        setSelectedLocation,
        selectedDate,
        setSelectedDate,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};
